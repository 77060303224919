<template>
    <div class="detail">
        <el-dialog title="订单详情" :visible.sync="dialogVisible" width="30%">
            <div class="item">
                订单ID： <span>{{ruleForm.id}}</span>
            </div>
            <div class="item">
                用户ID： <span>{{ruleForm.userId}}</span>
            </div>
            <div class="item">
                登录名： <span>{{ruleForm.username}}</span>
            </div>
            <div class="item">
                手机号： <span>{{ruleForm.telephone}}</span>
            </div>
            <div class="item">
                组织机构： <span>{{ruleForm.deptName}}</span>
            </div>
            <div class="item">
                咨询师ID： <span>{{ruleForm.consultId}}</span>
            </div>
            <div class="item">
                咨询师姓名： <span>{{ruleForm.consultName}}</span>
            </div>
            <!-- <div class="item">
                测评计划ID： <span>{{ruleForm.planId}}</span>
            </div>
            <div class="item">
                计划名称： <span>{{ruleForm.planName}}</span>
            </div> -->
            <div class="item">
                价格： <span>{{ruleForm.price}}</span>
            </div>
            <!-- <div class="item">
                折扣价： <span>{{ruleForm.discountPrice}}</span>
            </div> -->
            <div class="item">
                支付状态： 
                <!-- <span>{{ruleForm.status}}</span> -->
                <span v-if="ruleForm.status === 1">支付中</span>
              <span v-if="ruleForm.status === 2">已支付</span>
              <span v-if="ruleForm.status === 3">已取消</span>
              <span v-if="ruleForm.status === 4">订单已关闭</span>
              <span v-if="ruleForm.status === 5">退款中</span>
              <span v-if="ruleForm.status === 6">已退款</span>
            </div>
            <!-- <div class="item">
                完成进度： <span v-if="ruleForm.total">{{ruleForm.completed}}/{{ruleForm.total}}</span>
            </div> -->
            <div class="item">
                订单时间： <span>{{ruleForm.createTime}}</span>
            </div>
            <div class="item">
                支付时间： <span>{{ruleForm.paymentTime}}</span>
            </div>
            <div class="item">
                备注： <span v-if="!isEdit">{{ruleForm.memo}}</span>
                <el-input v-if="isEdit" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容"
                    v-model="ruleForm.memo">
                </el-input>
            </div>
            <div class="bottom">
                <el-button type="primary" @click="isEdit = true" size="mini" v-show="!isEdit">修改</el-button>
                <el-button type="primary" @click="update" size="mini" v-show="isEdit">保存</el-button>
            </div>
            <!-- <span>这是一段信息</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span> -->
        </el-dialog>
    </div>
</template>

<script>
import { orderDetail, updateOrderDetail } from '@/api/order/recordOrder'
export default {
    data() {
        return {
            dialogVisible: false,
            ruleForm: {},
            isEdit: false
        }
    },
    methods: {
        open(val) {
            console.log(val);
            this.isEdit = false;
            this.getOrderDetail(val);
            this.dialogVisible = true;
        },
        // 获取订单详情
        async getOrderDetail(val) {
            const res = await orderDetail(val);
            console.log(res);
            if (res.code === 200) {
                this.ruleForm = res.data;
            }
        },
        // 修改订单详情
        async update() {
            const res = await updateOrderDetail(JSON.stringify({ memo: this.ruleForm.memo, id: this.ruleForm.id }));
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    message: '保存成功',
                    type: 'success'
                });
                this.isEdit = false;
            } else {
                this.$message({
                    showClose: true,
                    message: '修改失败！' + res.msg,  
                    type: 'error'
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.detail {
    .item {
        margin-bottom: 15px;
    }

    .bottom {
        height: 50px;
        text-align: center;
    }
}
</style>