<template>
  <div class="result">
    <!-- 测评结果 -->
    <div class="btn">
      <div class="searchBtn">
        <el-select v-model="gaugeId" placeholder="请先选择量表" size="small" @change="getResultList">
          <el-option v-for="item in GaugeList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-cascader :options="OrganizationsList" v-model="searchInfo.deptId" size="small" placeholder="请选择组织机构"
          @focus="judgeGauge" :show-all-levels="false"
          :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }">
        </el-cascader>
        <!-- <el-cascader v-model="searchInfo.deptId" size="small" placeholder="请选择组织机构" :options="OrganizationsList"
          :props="{ checkStrictly: true }" clearable>
        </el-cascader> -->
        <el-select v-model="searchInfo.planId" placeholder="请选择测评计划" @focus="judgeGauge" size="small">
          <el-option v-for="item in PlanList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-input placeholder="请输入姓名" @focus="judgeGauge" v-model="searchInfo.nickname" clearable size="small">
        </el-input>
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        
      </div>
      <div class="anotherBtn">
        <!-- <el-button type="primary" size="small" @click="deleteResult">回收站</el-button> -->
         <el-button type="primary" size="small" @click="exportData(2)">导出测评数据</el-button>
         <!-- <el-dropdown style="margin-left: 5px;" split-button type="primary" @command="exportData" size="small">
          导出测评数据
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="1">全部数据</el-dropdown-item>
            <el-dropdown-item :command="2">勾选数据</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
       <el-button type="primary" size="small" @click="handleCommand(2)">导出测评报告</el-button>
        <!-- <el-dropdown style="margin-left: 5px;" split-button type="primary" @command="handleCommand" size="small">
          导出word报告
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="1">全部报告</el-dropdown-item>
            <el-dropdown-item :command="2">勾选报告</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </div>
    <div class="tableContent">
      <el-table :data="tableData" border v-loading="loading" style="width: 100%"
        @selection-change="handleSelectionChange" size="small" height="730">
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column prop="id" label="ID">
        </el-table-column>
        <el-table-column prop="userAnswerId" label="报告">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.userAnswerId" @click="toLook(scope.row.userAnswerId)">查看</el-button>
            <el-button type="text" v-else disabled>查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="姓名" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">
            <span v-if="scope.row.sex === 1"> 男</span>
            <span v-if="scope.row.sex === 2"> 女</span>
            <span v-if="scope.row.sex === 3"> 未设置</span>
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄">
        </el-table-column>
        <el-table-column prop="submitTime" label="提交时间" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.submitTime }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="答题时长">
          <template slot-scope="scope">
            <span>{{ scope.row.time }}</span>
          </template>
        </el-table-column>
        
        <el-table-column v-for="(item) in scoreWarningVOMap" :key="item" :label="item">

          <template slot-scope="scope">
            <!-- 判断当前维度是否设置了预警 -->
            <!-- 1.如果未设置预警，直接显示分值 -->
            <span v-for="ele in scope.row.dimensionProjectVOList" :key="ele.id">
              <template v-if="ele.name === item">
                <template v-if="ele.level">
                  <span v-show="ele.level === 1" class="one">{{ele.score}}</span>
                  <span v-show="ele.level === 2" class="two">{{ele.score}}</span>
                  <span v-show="ele.level === 3" class="three">{{ele.score}}</span>
                </template>
                <template v-else>
                  {{ele.score}}
                </template>
                
              </template>
            </span>
            
            <!-- <template v-if="scope.row.scoreWarningVOMap[index]">
              {{ scope.row.scoreWarningVOMap[index][item] }} -->
              <!-- <span v-if=" !scope.row.scoreWarningVOMap[index][item].level">{{
                  scope.row.scoreWarningVOMap[index][item].score
              }}</span> -->
              <!-- 2.设置了预警 -->

              <!-- <template v-if="scope.row.scoreWarningVOMap[index][item].level">
                <span v-if="scope.row.scoreWarningVOMap[index][item].level === 1" class="one">{{
                    scope.row.scoreWarningVOMap[index][item].score
                }}</span>
                <span v-if="scope.row.scoreWarningVOMap[index][item].level === 2" class="two">{{
                    scope.row.scoreWarningVOMap[index][item].score
                }}</span>
                <span v-if="scope.row.scoreWarningVOMap[index][item].level === 3" class="three">{{
                    scope.row.scoreWarningVOMap[index][item].score
                }}</span>
              </template> -->
            <!-- </template> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
       <!-- <span>共 {{total}} 条</span> -->
       <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total, prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
    </div>
  </div>
</template>

<script>
import { getPlanResultList, deletePlanResult, exportPlanResult, exportZIP } from '@/api/evaluationManagement/result'
import { getOrganizationList } from '@/api/system/organization'
import { getGaugeList } from '@/api/gauge/gaugeList.js'
import { getPlanList } from '@/api/evaluationManagement/plan'
export default {
  data() {
    return {
      gaugeId: null,
      loading: false,
      total: 0, // 条数
      searchInfo: {
        deptId: null,
        planId: null,
        nickname: null
      },
      paging: {
        p: 1,
        ps: 200,
        total: 0
      },
      tableData: [
        // {id: 1, age: 16, nickname: 'hj', sex: 'nan', submitTime: '', time: '0:10', userAnswerId: 20, scoreWarningVOMap: [{name: '111', level: 1, score: 20}, {name: '333', level: 1, score: 20}]},
        // {id: 2, age: 16, nickname: 'hj', sex: 'nan', submitTime: '', time: '0:10', userAnswerId: 20, scoreWarningVOMap: [{name: '111', level: 1, score: 20}, {name: '222', level: 1, score: 20}]},
      ],
      GaugeList: null,
      scoreWarningVOMap: [], // 表头数据
      OrganizationsList: [], // 组织机构列表
      PlanList: [], //测评计划列表
      deleteResultIdList: [],
      selectedList: [] //用户点击选择的数组
    }
  },
  methods: {
    // 导出报告
    handleCommand(val) {
      if(val === 1) {
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/plan/batch/exportZip?gaugeId=${this.gaugeId}&ids=0&type=1&way=${val}&userId=${Number(JSON.parse(sessionStorage.getItem('userInfo')).id)}`;
        elink.click();
      } else {
        if(this.deleteResultIdList.length > 0) {
        let list = this.deleteResultIdList.join(',');
        // console.log('ids', list)
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/plan/batch/exportZip?gaugeId=${this.gaugeId}&ids=${list}&type=1&way=${val}`;
        elink.click();
        // this.exportZip(this.deleteResultIdList, val)
      } else {
        this.$message({
          showClose: true,
          message: '请选择需要导出的测评数据！'
        });
      }
      }
      
    },
    async exportZip(val, val2) {
      const res = await exportZIP({userAnswerIds: [12547], type: 0});
      console.log(res);


      // var reader = new FileReader()
      //   reader.readAsDataURL(res)
      //   reader.onload = function (e) {
      //     // 转换完成，创建一个a标签用于下载
      //     var a = document.createElement('a')
      //     a.download = '个案辅导报告.zip' // 设置下载的文件名称
      //     a.href = e.target.result
      //     // 兼容触发click
      //     var evt = document.createEvent('MouseEvents')
      //     evt.initEvent('click', true, true)
      //     a.dispatchEvent(evt);
      //   }

      
      // const blob = new Blob([res]);  // 把得到的结果用流对象转一下
      //   // console.log(blob)
      //   var a = document.createElement("a"); //创建一个<a></a>标签
      //   a.href = URL.createObjectURL(blob); // 将流文件写入a标签的href属性值
      //   a.download = "测评报告.zip"; //设置文件名
      //   a.style.display = "none";  // 障眼法藏起来a标签
      //   document.body.appendChild(a); // 将a标签追加到文档对象中
      //   a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      //   a.remove();
    },
    // 获取量表列表
    async getGaugesList() {
      const res = await getGaugeList({ p: 1, ps: 100000, status: 2 })
      // console.log(res);
      if (res.code === 200) {
        this.GaugeList = res.data.records;
      }
    },
    // 切换分页
    handleCurrentChange(val) {
            this.paging.p = val;
            this.getResultList();
        },
    // 获取测评结果列表
    async getResultList() {
      this.loading = true;
      let info = JSON.parse(JSON.stringify(this.searchInfo));
      // console.log(info);
      // 如果组织机构选择的选择的是全部需要把值改为null
      if (info.deptId === -1) {
        info.deptId = null
      }
      if (info.planId === -1) {
        info.planId = null;
      }
      console.log(info);
      const res = await getPlanResultList(this.paging,this.gaugeId, { ...info });
      console.log(res);
      if (res.code === 200) {
        this.loading = false;
        this.tableData = res.data.evaluationResultVOS;
        this.paging.total = res.data.total;
        // this.tableData
        // 转换表头
        // 对象直接遍历出来会乱序，所以要把对象转换为数组排序
        this.scoreWarningVOMap = [];
        if (res.data.evaluationResultVOS.length > 0) {
          let arr = res.data.evaluationResultVOS[0].dimensionProjectVOList;
          arr.forEach(item => {
            this.scoreWarningVOMap.push(item.name);
            // console.log(item)
          })
          // let newKey = Object.keys(arr).sort();
          // let newObj = {};
          // for (var i = 0; i < newKey.length; i++) {
          //   newObj[newKey[i]] = arr[newKey[i]];;
          // }
          // console.log(newObj)
          // for (let key in newObj) {
          //   this.scoreWarningVOMap.push(key);
          // }
        }
        // console.log(this.scoreWarningVOMap)
      }
    },
    // 获取组织机构列表
    async getOrganizationsList() {
      const res = await getOrganizationList()
      // console.log('组织机构');
      // console.log(res);
      if (res.code === 200) {
        this.OrganizationsList = res.data;
        this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList];
      }
    },
    // 获取测评列表
    async getList() {
      const res = await getPlanList({ p: 1, ps: 100000 });
      console.log(res);
      if (res.code === 200) {
        this.PlanList = res.data.records;
        this.PlanList = [{ id: -1, name: '全部' }, ...this.PlanList];
        // this.PlanList.push()
      }
    },
    // 点击搜索
    search() {
      if (this.gaugeId === null) {
        this.$message({
          showClose: true,
          message: '请先选择量表'
        });
      } else {
        this.getResultList();
      }

    },
    // 用户点击选择
    handleSelectionChange(val) {
      console.log(val);
      this.selectedList = val;
      this.deleteResultIdList = [];
      if (val.length > 0) {
        val.forEach(item => {
          this.deleteResultIdList.push(item.userAnswerId)
        })
      }
    },
    // 移入回收站
    async deleteResult() {
      if (this.deleteResultIdList.length > 0) {
        const res = await deletePlanResult(JSON.stringify(this.deleteResultIdList));
        console.log(res)
        if (res.code === 200) {
          this.getResultList();
        } else {
          this.$message({
            showClose: true,
            message: '操作失败' + res.msg,
            type: 'error'
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: '请选择测评结果！'
        });
      }
    },
    // 导出
    async exportData(val) {
      console.log(val);
      if(val === 1) {
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/plan/excel/export?gaugeId=${this.gaugeId}&type=1&userAnswerIds=0&way=1&userId=${Number(JSON.parse(sessionStorage.getItem('userInfo')).id)}`;
        // elink.href = `http://101.34.136.224:8080/warning/export?ids=${list}&type=${val}`;
        elink.click();
      } else {
        if (this.selectedList.length > 0) {
        console.log(this.selectedList);
        let userAnswerIds = [];
        this.selectedList.forEach(item => {
          userAnswerIds.push(item.userAnswerId);
        });
        // let info = {gaugeId: this.gaugeId, userAnswerIds: userAnswerIds, type: 1};
        let list = userAnswerIds.join(',');
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/plan/excel/export?gaugeId=${this.gaugeId}&type=1&userAnswerIds=${list}&way=2&userId=${Number(JSON.parse(sessionStorage.getItem('userInfo')).id)}`;
        // elink.href = `http://101.34.136.224:8080/warning/export?ids=${list}&type=${val}`;
        elink.click();
        console.log(userAnswerIds)
      } else {
        this.$message({
          showClose: true,
          message: '请选择测评结果！'
        });
      }
      }
      

    },
    // // 导出
    // async exportData() {
    //   if (this.selectedList.length > 0) {
    //     console.log(this.selectedList);
    //     let userAnswerIds = [];
    //     this.selectedList.forEach(item => {
    //       userAnswerIds.push(item.userAnswerId);
    //     });
    //     // let info = {gaugeId: this.gaugeId, userAnswerIds: userAnswerIds, type: 1};
    //     let list = userAnswerIds.join(',');
    //     const elink = document.createElement('a');
    //     elink.href = `${this.$store.state.requestApi}/plan/excel/export?gaugeId=${this.gaugeId}&type=1&userAnswerIds=${list}`;
    //     // elink.href = `http://101.34.136.224:8080/warning/export?ids=${list}&type=${val}`;
    //     elink.click();
    //     console.log(userAnswerIds)
    //   } else {
    //     this.$message({
    //       showClose: true,
    //       message: '请选择测评结果！'
    //     });
    //   }

    // },
    // 点击查看
    toLook(val) {
      this.$router.push({
        path: '/home/viewReport',
        query: {
          id: val,
          gaugeId: this.gaugeId
        }
      })
    },
    // 判断是否选择了量表
    judgeGauge() {
      if (this.gaugeId === null) {
        this.$message({
          showClose: true,
          message: '请先选择量表！'
        });
      }
    },
  },
  created() {
    // console.log(sessionStorage.getItem('userInfo'))
    this.getGaugesList();
    this.getOrganizationsList();
    this.getList();
    if (this.$route.query.id) {
      this.gaugeId = Number(this.$route.query.id);
      this.getResultList();
    }
  },
  mounted() {
    this.$message({
          showClose: true,
          message: '请先选择量表'
      });
  }

}
</script>

<style lang="scss" scoped>
.result {
  padding: 20px;
  padding-bottom: 0px;
  .block{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // 一级预警
  .one {
    color: red;
  }

  // 二级预警
  .two {
    color: rgb(255, 119, 0);
  }

  // 三级预警
  .three {
    color: rgb(248, 191, 2);
  }

  .btn {
    display: flex;
    justify-content: space-between;

    .anotherBtn {
      .el-button {
        margin-left: 20px;
      }
    }
  }

  .tableContent {
    margin-top: 20px;
  }

}
</style>