import request from '@/utils/request'
// 预约-订单管理
export function recordOrderList(p, ps, data) {
    return request({
      url: `/orders/reserve/page/${p}/${ps}`,
      method: 'post',
      data
    });
  }
  // 获取订单详情
export function orderDetail(id, params) {
  return request({
    url: `/orders/reserve/${id}`,
    method: 'GET',
    params
  });
}
// 修改订单详情
export function updateOrderDetail( data) {
  return request({
    url: '/orders/reserve',
    method: 'post',
    data
  });
}
// 删除订单
export function deleteOrderAPI( data) {
return request({
  url: '/orders/reserve',
  method: 'delete',
  data
});
}