<template>
  <div class="order">
    <!-- 订单管理 -->
    <div class="searchBtn">
      <el-cascader :options="OrganizationsList" v-model="searchInfo.deptId" size="small" placeholder="请选择组织机构"
        :show-all-levels="false" :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }">
      </el-cascader>
      <el-select v-model="searchInfo.status" placeholder="请选择订单状态" size="small">
        <el-option label="全部" :value="0">
        </el-option>
        <el-option label="支付中" :value="1">
        </el-option>
        <el-option label="已支付" :value="2">
        </el-option>
        <el-option label="已取消" :value="3">
        </el-option>
        <el-option label="订单已关闭" :value="4">
        </el-option>
      </el-select>
      <div>
        <el-date-picker size="small" v-model="searchInfo.startTime" type="date" placeholder="开始时间"
          style="margin-right:10px" format="yyyy-MM-dd" value-format="yyyy-MM-dd 00:00:00">
        </el-date-picker>
        <span>至</span>
        <el-date-picker size="small" v-model="searchInfo.endTime" type="date" placeholder="结束时间" format="yyyy-MM-dd"
          value-format="yyyy-MM-dd 23:59:59">
        </el-date-picker>
      </div>
      <el-button type="primary" size="small" @click="search">搜索</el-button>
      <el-button type="primary" size="small" @click="deleteOrders">删除</el-button>
      <el-button type="primary" size="small" @click="exportData">导出</el-button>
    </div>
    <div class="tableContent">
      <template>
        <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="id" label="订单ID" width="80" fixed></el-table-column>
          <el-table-column prop="number" label="商户订单号" width="190" fixed></el-table-column>
          <el-table-column prop="userId" label="用户ID" width="80" fixed>
          </el-table-column>
          <el-table-column prop="openId" label="openId" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="username" label="登录名" width="120" show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column prop="province" label="UID">
          </el-table-column> -->
          <el-table-column prop="telephone" label="手机号" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="deptName" label="组织机构" width="150" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="consultId" label="咨询师ID" width="150" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="consultName" label="咨询师姓名" width="150" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="price" label="价格" width="80">
          </el-table-column>
          <el-table-column prop="status" label="支付状态" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 1">支付中</span>
              <span v-if="scope.row.status === 2">已支付</span>
              <span v-if="scope.row.status === 3">已取消</span>
              <span v-if="scope.row.status === 4">订单已关闭</span>
              <span v-if="scope.row.status === 5">退款中</span>
              <span v-if="scope.row.status === 6">已退款</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="订单时间" width="170">
          </el-table-column>
          <el-table-column prop="paymentTime" label="支付时间" width="170">
          </el-table-column>

          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="openDetail(scope.row.id)">查看</el-button>
              <!-- <el-button type="text" size="small">编辑</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
        layout="total, prev, pager, next, jumper" :total="paging.total">
      </el-pagination>
    </div>
    <OrderDetailVue ref="detail"></OrderDetailVue>
  </div>
</template>

<script>
import { getOrganizationList } from '@/api/system/organization';
import { recordOrderList, deleteOrderAPI } from '@/api/order/recordOrder'
import OrderDetailVue from './OrderDetail.vue';
export default {
  data() {
    return {
      tableData: [],
      OrganizationsList: null, // 组织机构列表
      searchInfo: {
        deptId: null,
        startTime: null,
        endTime: null,
        status: null // 订单状态
      },
      paging: {
        p: 1,
        ps: 20,
        total: 0
      },
      deleteIdList: [],// 需要删除的订单数组
      selectedDel: [] 
    }
  },
  methods: {
    // 获取组织机构列表
    async getOrganizationsList() {
      const res = await getOrganizationList()
      // console.log('组织机构');
      console.log(res);
      if (res.code === 200) {
        this.OrganizationsList = res.data;
        this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
        // this.deleteNullChildren(this.OrganizationsList)
      }
    },
    // 获取订单列表
    async getOrderList() {
      let info = JSON.parse(JSON.stringify(this.searchInfo));
      if (info.deptId === -1) {
        info.deptId = null
      }
      if (info.status === 0) {
        info.status = null
      }
      const res = await recordOrderList(this.paging.p, this.paging.ps, info);
      console.log(res);
      if (res.code === 200) {
        this.tableData = res.data.records;
        this.paging.total = res.data.total;
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.p = val;
      this.getOrderList();
    },
    // 点击搜索
    search() {
      this.paging.p = 1;
      this.getOrderList();
    },
    // 点击详情
    openDetail(val) {
      this.$refs.detail.open(val);
    },
    // 点击选择订单
    handleSelectionChange(val) {
      // console.log(val)
      this.selectedDel = val;


    },
    // 删除
    deleteOrders() {
      if (this.selectedDel.length < 1) {
        this.$message({
          showClose: true,
          message: '请选择需要删除的订单！'
        });
      } else {
        this.$confirm('确认删除此订单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async() => {
          let arr = [];
          this.selectedDel.forEach(item => {
            arr.push(item.id);
          });
          const res = await deleteOrderAPI(JSON.stringify(arr));
          console.log(res);
          if (res.code === 200) {
            
            this.$message({
              showClose: true,
              message: '删除成功！',
              type: 'success'
            });
            
          } else {
            this.$message({
              showClose: true,
              message: '删除失败!' + res.msg,
              type: 'error'
            });
          }
          this.getOrderList();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      }
    },
    // 导出
    async exportData() {
      // console.log(this.selectedList);
      if (this.selectedDel.length > 0) {
        console.log(this.selectedDel);
        let userAnswerIds = [];
        this.selectedDel.forEach(item => {
          userAnswerIds.push(item.id);
        });
        // let info = {gaugeId: this.gaugeId, userAnswerIds: userAnswerIds, type: 1};
        let list = userAnswerIds.join(',');
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/orders/reserve/export?ids=${list}`;
        // elink.href = `http://101.34.136.224:8080/warning/export?ids=${list}&type=${val}`;
        elink.click();
        console.log(userAnswerIds)
      } else {
        this.$message({
          showClose: true,
          message: '请选择订单！'
        });
      }

    }

  },
  created() {
    this.getOrderList();
    this.getOrganizationsList();
  },
  components: { OrderDetailVue }
}
</script>

<style lang="scss" scoped>
.order {
  padding: 20px;

  .tableContent {
    margin-top: 20px;
  }
}
</style>