<template>
    <div class="answerRecord">
        <!-- 答题记录 -->
        <div class="region">
            <div class="rg">
                <div class="item">
                    <span class="title">答题记录</span>
                </div>
                <div class="Guidelines item">
                    <span class="text">指导语</span>
                    <div class="prompt">
                        <p>
                           {{tableInfo.guidelines}}
                        </p>
                        <!-- <p>1. 请您认真、如实地根据自己在一周内;</p>
                        <p>1. 请您认真、如实地根据自己在一周内;</p>
                        <p>1. 请您认真、如实地根据自己在一周内;</p>
                        <p>1. 请您认真、如实地根据自己在一周内;</p> -->
                    </div>
                </div>
                <div class="item" v-for="item in tableInfo.problemVOList" :key="item.id">
                    <span class="text">{{item.problem.orderNum}}. {{item.problem.name}}</span>
                    <span v-if="item.problem.type === 1 " class="text">(单选)</span>
                    <span v-else-if="item.problem.type === 2 " class="text">(多选)</span>
                    <span v-else-if="item.problem.type === 3 " class="text">(图片单选)</span>
                    <span v-else-if="item.problem.type === 4 " class="text">(图片多选)</span>
                    <div v-if="item.problem.url" class="">
                        <img :src="item.problem.url" alt="">
                    </div>
                    <div class="answerList" v-for="ele in item.answerList" :key="ele.id">
                        <span  :class="ele.selected ? 'choose' : ''">{{ ele.name
                        }}</span>
                        <div v-if="ele.url" class="">
                            <img :src="ele.url" alt="">
                        </div>
                    </div>
                </div>
                <button @click="back">返回</button>
            </div>
        </div>
    </div>
</template>

<script>
import {getAnswersList} from '@/api/dangerWarning/warning'
export default {
    data() {
        return{
            tableInfo: {
                guidelines: ''
            },
        }
    },
    methods: {
        back() {
            if( this.$route.path === '/home/fileDetails1'){
                this.$router.push('/home/healthRecords/record')
            } else if( this.$route.path === '/home/answerReport'){
                this.$router.push('/home/personalWarning')
            }
            // this.$router.push({
            //     path: '/home/healthRecords/record'
            // })
        },
        // 获取答题列表
        async getAnswerList(val) {
            const res = await getAnswersList({id: val});
            console.log(res);
            if (res.code === 200) {
                this.tableInfo = res.data
            }
        }
    },
    created() {
        console.log(this.$route.query.id)
        this.getAnswerList(this.$route.query.id);
    }
}
</script>

<style lang="scss" scoped>
.answerRecord {

    .region {
        
        background: white;
        margin: auto;
        padding-bottom: 50px;
        img{
            margin-top: 10px;
            height: 100px;
            width: 100px;
        }
        .item {
            margin-bottom: 30px;

            .title {
                font-size: 16px;
                font-weight: 600;
            }

            .text {
                font-size: 16px;

            }

            p {
                font-size: 14px;
                color: #7D7D7D;
                margin: 3px 0px;

            }

            .prompt {
                border: 1px solid #DDDDDD;
                margin-top: 10px;
                padding: 8px;
            }

            .answerList {
                // height: 40px;
                background: #FFFFFF;
                // border-radius: 4px;
                // border: 1px solid #EEEEEE;
                margin-top: 10px;
                // padding-left: 20px;
                span {
                    display: block;
                    font-size: 14px;
                    height: 40px;
                    border: 1px solid #EEEEEE;
                    border-radius: 4px;
                    padding-left: 20px;
                    // color: #7D7D7D;
                    line-height: 40px;
                }
                .choose {
                // background: #bbf4ff;
                background: rgba($color: #BAA796, $alpha: 0.1);
            }
            }
        }
        button{
            cursor: pointer;
            width: 100%;
            background: var(--btn-color);
            height: 40px;
            border: none;
            color: white;
            border-radius: 4px;
        }
    }
}

@media screen and (max-width: 2160PX)and (min-width: 751PX) {
    .region {
        width: 900px;

        .rg {
            padding: 40px 100px;

            .topReport {
                width: 650px;
                // height: 40px;
                // background: #028DA9;
            }
        }

        // background: white;

    }
}

@media screen and (max-width: 750PX) {
    .region {
        width: 100%;

        .rg {
            padding-top: 20px;
            // padding: 20px 20px;
            width: 90%;
            margin: auto;

            // .topReport {
            //     // width: 100%;
            //     // margin: auto;
            //     // width: 90%;
            //     // height: 40px;
            //     // background: #028DA9;
            // }
        }

        // background: white;

    }
}
</style>