// 测评计划=计划概况、参与用户
import request from '@/utils/request'
// 获取计划列表
export function getPlanList(params) {
  return request({
    url: 'plan/page',
    method: 'get',
    params
  });
}
// 添加测评计划、 编辑
export function updatePlanEvaluation(data) {
    return request({
      url: '/plan/save',
      method: 'post',
      data
    });
}
// 获取测评计划详情
export function getPlanDetail(params) {
    return request({
      url: 'plan',
      method: 'get',
      params
    });
}
// 搜索 量表列表
export function getGaugeList(data) {
    return request({
      url: '/plan/gauge/list',
      method: 'post',
      data
    });
}
// 添加量表
export function addGauge(data) {
    return request({
      url: '/plan/save/gauge',
      method: 'post',
      data
    });
}
// 修改量表
export function updateGauges(data) {
    return request({
      url: '/plan/updater/gauge',
      method: 'post',
      data
    });
}
// 删除量表
export function deleteGauge(data) {
    return request({
      url: '/plan/gauge',
      method: 'delete',
      data
    });
}
// 删除计划
export function deletePlan(data) {
    return request({
      url: '/plan',
      method: 'delete',
      data
    });
}
// 获取参与用户列表
export function getParticipatingUsers(params) {
  return request({
    url: '/plan/userpage',
    method: 'get',
    params
  });
}
// 删除参与用户
export function deleteParticipatingUsers(id, data) {
  return request({
    url: `/plan/${id}`,
    method: 'delete',
    data
  });
}
// 新增参与用户
export function addParticipatingUsers( data) {
  return request({
    url: '/plan/save/user',
    method: 'post',
    data
  });
}
// 获取指定推送添加人员列表
export function getAddUser( params) {
  return request({
    url: '/plan/user',
    method: 'get',
    params
  });
}
// 修改序号
export function updateOrder(planId,number) {
  return request({
    url: `/plan/number/${planId}/${number}`,
    method: 'put'
  });
}
// 获取计划付费类型
export function payType( params) {
  return request({
    url: '/plan/type',
    method: 'get',
    params
  });
}